<template>
  <el-drawer @close="back" v-model="isOpen" title="Редактирование тарифа">
    <el-form
      :model="form"
      :rules="rules"
      label-position="top"
      label-width="100px"
      class="py-4 px-5"
      ref="form-tariff"
    >
      <el-form-item prop="title" label="Название">
        <el-input v-model.trim="form.title"></el-input>
      </el-form-item>
      <el-form-item prop="tariff" label="Тариф(число)">
        <el-input v-model.number="form.tariff"></el-input>
      </el-form-item>
      <el-form-item prop="type" label="Тип">
        <el-select v-model="form.type">
          <el-option :value="1" label="Разметчик"></el-option>
          <el-option :value="2" label="Верификатор"></el-option>
        </el-select>
      </el-form-item>
      <div class="text-right mt-3">
        <el-button
          v-if="form.state === -1"
          @click="restoreTarif"
          type="success"
          plain
          >Восстановить</el-button
        >
        <el-button
          v-if="![1, -1].includes(form.state)"
          @click="removeTariff"
          plain
          type="danger"
          >Удалить</el-button
        >
        <el-button
          v-if="form.state === 0"
          @click="toActive"
          plain
          type="success"
          >Сделать активным</el-button
        >
        <el-button @click="submit" plain type="primary">Сохранить</el-button>
      </div>
    </el-form>
  </el-drawer>
</template>

<script>
import {
  createTariff,
  deleteTariff,
  getItemTariff,
  updateTariff,
} from "@/api/tarif";

export default {
  name: "createTarifForm",
  emits: ["getList"],
  props: {
    tarif_id: String,
  },
  popupController: null,
  data() {
    return {
      isOpen: true,
      form: {
        title: "",
        tariff: null,
        state: 1,
      },
      rules: {
        title: [
          { required: true, message: "Заполните все поля", trigger: "blur" },
        ],
        tariff: [
          {
            required: true,
            message: "Заполните все поля",
            trigger: "blur",
            type: "number",
          },
        ],
        type: [
          { required: true, message: "Заполните все поля", trigger: "blur" },
        ],
      },
    };
  },
  async mounted() {
    if (this.tarif_id !== "new") {
      await this.getItemTarif();
    }
  },
  methods: {
    async getItemTarif() {
      let { data } = await getItemTariff(this.tarif_id);
      this.form = { ...data };
      console.log(data);
    },
    back() {
      this.isOpen = false;
      this.$emit("getList");
      this.$router.replace({ name: "tarif" });
    },
    submit() {
      this.$refs["form-tariff"].validate((k) => {
        if (k) {
          if (this.tarif_id !== "new") {
            this.updateTariff();
          } else {
            console.log("we are here");
            this.createTariff();
          }
        }
      });
    },
    async updateTariff() {
      let data = await updateTariff(this.tarif_id, this.form);
      console.log(data);
      if (data._success) {
        this.back();
      }
    },
    async createTariff() {
      let data = await createTariff(this.form);
      console.log(data);
      if (data._success) {
        this.back();
      }
    },
    async restoreTarif() {
      let form = { ...this.form };
      form.state = 1;
      let data = await updateTariff(this.tarif_id, form);
      console.log(data);
      if (data._success) {
        this.form.state = 1;
        this.$emit("backWithUpdate", this.form.state);
        this.back();
      }
    },
    async toActive() {
      let form = { ...this.form };
      form.state = 1;
      let data = await updateTariff(this.tarif_id, form);
      if (data._success) {
        this.back();
      }
    },
    async removeTariff() {
      let form = { ...this.form };
      form.state = -1;
      let data = await deleteTariff(this.tarif_id);
      console.log(data);
      if (data._success) {
        this.form.state = -1;
        this.$emit("backWithUpdate", this.form.state);
        this.back();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form:deep(.el-form-item__label) {
  line-height: 20px !important;
}
</style>
