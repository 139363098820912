import { createTariff, deleteTariff, getItemTariff, updateTariff } from "@/api/tarif";
export default {
  name: "createTarifForm",
  emits: ["getList"],
  props: {
    tarif_id: String
  },
  popupController: null,
  data() {
    return {
      isOpen: true,
      form: {
        title: "",
        tariff: null,
        state: 1
      },
      rules: {
        title: [{
          required: true,
          message: "Заполните все поля",
          trigger: "blur"
        }],
        tariff: [{
          required: true,
          message: "Заполните все поля",
          trigger: "blur",
          type: "number"
        }],
        type: [{
          required: true,
          message: "Заполните все поля",
          trigger: "blur"
        }]
      }
    };
  },
  async mounted() {
    if (this.tarif_id !== "new") {
      await this.getItemTarif();
    }
  },
  methods: {
    async getItemTarif() {
      let {
        data
      } = await getItemTariff(this.tarif_id);
      this.form = {
        ...data
      };
      console.log(data);
    },
    back() {
      this.isOpen = false;
      this.$emit("getList");
      this.$router.replace({
        name: "tarif"
      });
    },
    submit() {
      this.$refs["form-tariff"].validate(k => {
        if (k) {
          if (this.tarif_id !== "new") {
            this.updateTariff();
          } else {
            console.log("we are here");
            this.createTariff();
          }
        }
      });
    },
    async updateTariff() {
      let data = await updateTariff(this.tarif_id, this.form);
      console.log(data);
      if (data._success) {
        this.back();
      }
    },
    async createTariff() {
      let data = await createTariff(this.form);
      console.log(data);
      if (data._success) {
        this.back();
      }
    },
    async restoreTarif() {
      let form = {
        ...this.form
      };
      form.state = 1;
      let data = await updateTariff(this.tarif_id, form);
      console.log(data);
      if (data._success) {
        this.form.state = 1;
        this.$emit("backWithUpdate", this.form.state);
        this.back();
      }
    },
    async toActive() {
      let form = {
        ...this.form
      };
      form.state = 1;
      let data = await updateTariff(this.tarif_id, form);
      if (data._success) {
        this.back();
      }
    },
    async removeTariff() {
      let form = {
        ...this.form
      };
      form.state = -1;
      let data = await deleteTariff(this.tarif_id);
      console.log(data);
      if (data._success) {
        this.form.state = -1;
        this.$emit("backWithUpdate", this.form.state);
        this.back();
      }
    }
  }
};